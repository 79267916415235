import React from 'react'
import FullWidthImage from './FullWidthImage'
import Navbar from './Navbar'
import styled from 'styled-components'

const MaxWidthContainer = styled.div`
  display: grid;
  margin: 0 auto;
  grid-template-rows: 24px 78px 160px;
  grid-template-columns: minmax(24px, auto) minmax(auto, 1300px) minmax(
      24px,
      auto
    );
  grid-template-areas:
    '. . .'
    '. nav .'
    '. title .';
`

const Title = styled.h1`
  grid-area: title;
  color: #f2f1eb;
  text-align: center;
  align-self: center;
  z-index: 1;
  margin-bottom: 24px;
`
const PageBanner = ({ location, img, title }) => {
  return (
    <MaxWidthContainer>
      <Navbar location={location} style={{ gridArea: 'nav', zIndex: 10 }} />
      {title && <Title>{title}</Title>}
      <FullWidthImage img={img} height={360} gridArea="1/1/4/4" />
    </MaxWidthContainer>
  )
}

export default PageBanner
